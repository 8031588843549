import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Tony's testimonial" />

    <div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-split-content full-width">
            <div className="content-width-medium">
              <h1 className="h3">“There’s not a day that goes by when I don’t listen to the program. I’m very thankful for my positive outlook on life.”</h1>
              <div className="div-block"><img width={74} src="/fonts/5ddcdd30d52c2f2f73c63597_12-People%20Avatar-Man.svg" alt="" className="case-study-logo" />
                <div>
                  <div className="handwriting">Tony</div>
                  <div>Wise Guide User</div>
                </div>
              </div>
            </div>
            <div className="content-width-large"><img src="/images/5dc99dd0c36766db2459390b_intercom-image.jpg" alt="" sizes="(max-width: 479px) 95vw, (max-width: 767px) 90vw, (max-width: 991px) 612px, 46vw" srcSet="/images/5dc99dd0c36766db2459390b_intercom-image-p-500.jpeg 500w, /images/5dc99dd0c36766db2459390b_intercom-image-p-1080.jpeg 1080w, /images/5dc99dd0c36766db2459390b_intercom-image.jpg 1224w" /></div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="w-layout-grid sidebar-layout case-study-layout">
            <div className="sidebar">
              <div className="card shadow-small">
                <ul className="w-list-unstyled">
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Name</h6>
                    <div>Tony</div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Programs</h6>
                    <div>How to be Positive</div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Results</h6>
                    <div>helped them gain confidence and a positive outlook.</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="content-width-large">
                <div className="article w-richtext">
                  <p>As Tony browsed the shelves in the B Dalton Bookstore in Westland Mall, Florida, he knew he wanted something to help him gain a more positive outlook on life. All those years ago, he chose an audio self-help program that would change his life. The program was How to be Positive, a guided meditation audio by Bob Griswold from Effective Learning Systems.</p>
                  <p>“I was looking for something to help me be a better person and to have a healthy outlook on life,” begins Tony. “So I selected the tape on how to be positive.”</p>
                  <p>When asked how it impacted on his life, Tony says, “Prior to listening to the tapes, I feel I was more of an introvert. But now, after many years of listening to many of the Effective Learning programs, I feel my personality has altered to more of an outgoing, social type of person.”</p>
                  <p>One of the unexpected changes was the effect on those closest to Tony. Not only did they see changes in him, the programs also helped them gain confidence and a positive outlook. “Most of all, I enjoy sharing the tapes with my wife and children and seeing them grow into more positive and self-confident people.”</p>
                  <p>“I always recommend the programs to my friend’s family and anyone that wants to make improvements in their life. I love them. There’s not a day that goes by when I don’t listen to the program. I’m very thankful for my positive outlook on life.”</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-justify-center">
            <div className="section-title-wide">
              <h3 className="h2 no-bottom-space">More success stories</h3>
            </div>
          </div>
          <div className="w-dyn-list">
            <div className="case-study-cards-grid w-dyn-items">
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc49d9315b303c82a6af7b_15-People%20Avatar-Man.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">The programs have enabled me to increase my focus on positive outcomes. The process of listening for 30 days seems to be an effective way to drive home points about positive approaches to problems</p><Link to="/dorsey/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc498444a1cc7c9762354a_26-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">Jenny was able to gain more confidence and lose weight using the Wise Guide app</p><Link to="/jenny/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc4bc87dc9835069f5ccfc_18-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">The program explained how to eject negatives and to choose to be confident. I started by smiling at other people and of being loving towards them. </p><Link to="/annie-marie/">Read their story</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DownloadApp />
  </Layout>
);
